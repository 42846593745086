html {
  font-size: 14px;
}

.row {
  margin-left: -14px;
  margin-right: -14px;
}

.header-link {
  color: White !important;
  padding-left: 7px;
}

.nav-link {
  color: White !important;
}

a:hover {
  color: grey;
}

.header-links {
  margin-left: 20px;
}
.page-card {
  margin-top: 30px;
}

nav-item > .dropdown-menu {
  right: 0;
  left: auto;
}

.div.dataTables_wrapper div.dataTables_filter label,
div.dataTables_wrapper div.dataTables_length label {
  text-align: left;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter select,
div.dataTables_wrapper div.dataTables_length input,
div.dataTables_wrapper div.dataTables_length select {
  width: auto;
}

div.dataTables_wrapper div.dataTables_length select {
  margin-left: 0.5rem;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5rem;
  display: inline-block;
}

.dataTables_info {
  padding-top: 1.3rem;
  padding-left: 0.5rem;
}

.dataTables_paginate {
  padding-top: 1rem;
}

.card-header > .card-title {
  display: inline-block;
  padding: 0.375rem 0;
  margin-bottom: 0;
}

tbody + thead {
  display: none;
}

input[type=checkbox], input[type=radio] {
	vertical-align:middle;
}